import axios from 'axios'

// initial state
const state = () => ({
  biomarker: [],
  publicBiomarker: []
})

// getters
const getters = {
  biomarker: ({ biomarker }) => biomarker,
  publicBiomarker: ({ publicBiomarker }) => publicBiomarker,
}

// actions
const actions = {
    async getBiomarker({ commit }) {
        const biomarker = (await axios.get('/meta/biomarker/')).data
        commit('setBiomarker', biomarker)
    },
    async getPublicBiomarker({ commit }) {
      const publicBiomarker = (await axios.get('/public/meta/biomarker/')).data
      commit('setPublicBiomarker', publicBiomarker)
  }
}

// mutations
const mutations = {
    setBiomarker(state, biomarker) {
        state.biomarker = biomarker
    },
    setPublicBiomarker(state, publicBiomarker) {
      state.publicBiomarker = publicBiomarker
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
