<template>
  <div>
    <el-dropdown trigger="click">
      <el-image style="height:45px;" :src="require(`./../../assets/dacy.png`)" fit="contain"></el-image>
      <template #dropdown>
        <el-dropdown-menu>
          <!--<el-dropdown-item @click="openHelpdesk"><i class="el-icon-question"></i>Hilfe</el-dropdown-item>
          <el-dropdown-item @click="openBlog"><i class="el-icon-document"></i>Neuigkeiten</el-dropdown-item>
          <el-dropdown-item @click="openHelpdesk('experten')"><i class="el-icon-medal"></i>Experten</el-dropdown-item>-->
          <el-dropdown-item @click="$router.push('/about')"><i class="el-icon-info"></i>Über</el-dropdown-item>
          <el-dropdown-item @click="openContact"><i class="el-icon-question"></i>Kontakt</el-dropdown-item>
          <el-dropdown-item @click="$router.push('/disclaimer')"><i class="el-icon-document"></i>Haftungsausschluss</el-dropdown-item>
          <el-divider v-if="onWindowsGui"></el-divider>
          <el-dropdown-item v-if="onWindowsGui" @click="changeTextSize(0.15)"><i class="el-icon-plus"></i>Text größer</el-dropdown-item>
          <el-dropdown-item v-if="onWindowsGui" @click="changeTextSize(-0.15)"><i class="el-icon-minus"></i>Text kleiner</el-dropdown-item>
        </el-dropdown-menu>
      </template>
    </el-dropdown>
  </div>
</template>

<script>
export default {
  name: "MainMenu",
  props: ['modelValue'],
  components: {},
  data() {
    return {
    }
  },
  mounted: async function () {
  },
  computed: {
    onWindowsGui: function() {
      return !!(window.gui)
    }
  },
  watch: {
    modelValue: function () {
    }
  },
  methods: {
    openContact() {
      let url = 'https://dacy.pro/#Kontakt'
      if(window.gui) window.gui.send('open-link', url)
      else window.open(url, "_blank");
    },
    openHelpdesk(site) {
      let url = 'https://dacyhelp.s2.giria.io/' + (typeof site === 'string' ? site : '')
      if(window.gui) window.gui.send('open-link', url)
      else window.open(url, "_blank");
    },
    openBlog() {
      let url = 'https://dacy.pro/bloguebersicht/'
      if(window.gui) window.gui.send('open-link', url)
      else window.open(url, "_blank");
    },
    changeTextSize(step) {
      if(window.gui) window.gui.send('change-zoom-factor', step)
    }
  }
}
</script>
<style scoped>
</style>
