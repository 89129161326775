<template>
  <div>

    <survey-patient-form-modal v-model="showSurveyPatientForm" :survey-patient="currentPatient" :selection-surveys="surveysOfTherapyProtocol"></survey-patient-form-modal>

  <el-dialog
      :title="dialogTitle"
      v-model="showForm"
      width="75%"
      :before-close="closeDialog">
    <el-row>
      <el-col :span="16">
        <h6 class="text-dacy">{{fullInfo}}</h6>
        <h6 class="text-dacy" v-if="currentTherapy.meta.protocol">{{currentTherapy.meta.protocol.title}}</h6>
        <h6 class="text-dacy" v-else>{{currentTherapy.meta.therapy.code}}</h6>
      </el-col>
      <el-col :span="8" class="text-right">
        <el-button v-if="therapyHasSurveys" @click="showSurveyPatientForm = true">Patient einer Studie zuweisen</el-button>
      </el-col>
    </el-row>

    <el-tabs v-model="tabIndex">
      <el-tab-pane label="Therapie">

        <el-row class="mt-4">
          <el-col :span="7">
            <date-picker-precision v-model="currentTherapy.date_start" v-model:precision="currentTherapy.date_start_precision" label_date="Therapiebeginn"></date-picker-precision>
          </el-col>
          <el-col :span="7" :offset="1">
            <date-picker-precision v-model="currentTherapy.date_end" v-model:precision="currentTherapy.date_end_precision" label_date="Therapieende"></date-picker-precision>
          </el-col>
          <el-col :span="8" :offset="1">
            <h6>Grund des Therapieendes</h6>
            <el-select v-model="currentTherapy.end_reason" class="w-100" :disabled="!currentTherapy.date_end">
              <el-option label="-- Keine Auswahl --" :value="null"></el-option>
              <el-option label="Reguläres Ende" value="E"></el-option>
              <el-option label="reguläres Ende mit Dosisreduktion" value="R"></el-option>
              <el-option label="reguläres Ende mit Substanzwechsel" value="W"></el-option>
              <el-option label="Abbruch wegen Nebenwirkungen" value="A"></el-option>
              <el-option label="Abbruch wegen Progress" value="P"></el-option>
              <el-option label="Abbruch aus sonstigen Gründen" value="S"></el-option>
              <el-option label="Patient verweigert weitere Therapie" value="V"></el-option>
              <el-option label="Patient verstorben" value="T"></el-option>
              <el-option label="Unbekannt" value="U"></el-option>
            </el-select>
          </el-col>
        </el-row>
        <el-row class="mt-4">
          <el-col :span="12">
            <h6>Diagnose</h6>
            <el-select size="medium" class="w-100" v-model="currentTherapy.diagnosis_id" filterable>
              <el-option
                v-for="item in allDiagnosis"
                :key="item.id"
                :label="(item.code + ', ' + item.title)"
                :value="item.id">
              </el-option>
            </el-select>
          </el-col>
          <el-col :span="11" :offset="1">
            <h6>Behandlungssituation</h6>
            <el-select size="medium" class="w-100" v-model="currentTherapy.treatmentsituation_id" filterable>
              <el-option
                v-for="item in allTreatmentSituations"
                :key="item.id"
                :label="item.title"
                :value="item.id">
              </el-option>
            </el-select>
          </el-col>
        </el-row>
        <el-row class="mt-4">
          <el-col :span="12">
            <h6>klinische Einheit</h6>
            <el-select size="medium" class="w-100" v-model="currentTherapy.therapy_unit_id" filterable>
              <el-option
                v-for="item in therapyunits"
                :key="item.id"
                :label="item.code"
                :value="item.id">
              </el-option>
            </el-select>
          </el-col>
          <el-col :span="11" :offset="1">
            <h6>Therapieprinzip</h6>
            <el-select size="medium" class="w-100" v-model="currentTherapy.therapy_id" filterable>
              <el-option
                v-for="item in therapiesForForm"
                :key="item.id"
                :label="(item.title ? item.title : item.code)"
                :value="item.id">
              </el-option>
            </el-select>
          </el-col>
        </el-row>
        <el-row class="mt-4">
          <el-col :span="12">
            <h6>Protokoll</h6>
            <el-select size="medium" class="w-100" v-model="currentTherapy.protocol_id" filterable>
              <el-option
                v-for="item in allTherapyProtocols"
                :key="item.id"
                :label="(item.meta && item.meta.protocolown ? item.meta.protocolown.title : (item.protocol ? item.protocol.title : ''))"
                :value="item.protocol_id">
              </el-option>
            </el-select>
          </el-col>
          <el-col :span="11" :offset="1">
            <h6>Status</h6>
            <el-radio-group v-model="currentTherapy.completed" v-if="patient_therapy_completed_states">
              <el-radio v-for="state in patient_therapy_completed_states" :key="state.id" :label="state.id" >{{state.title}}</el-radio>
            </el-radio-group>
          </el-col>
        </el-row>

        <el-row class="mt-4">
          <h6>Kommentar</h6>
          <el-col>
            <el-input
              type="textarea"
              :rows="2"
              placeholder="Kommentar"
              v-model="currentTherapy.comment"></el-input>
          </el-col>
        </el-row>

        <el-row class="mt-4" v-if="!currentTherapy.id">
          <h6>Verlaufsbeoachtung anlegen?</h6>
          <el-col>
            <el-checkbox v-model="form.addProgress">Ja, ich möchte gleichzeitig eine Verlaufsbeobachtung anlegen</el-checkbox>
          </el-col>
        </el-row>

        <el-row class="ml-4 mt-0" v-if="!currentTherapy.id && form.addProgress">
          <el-col :span="11">
            <h6>Name der Verlaufsbeobachtung</h6>
            <el-input v-model="form.progress.title"></el-input>
          </el-col>
          <el-col :span="12" :offset="1">
            <h6>Verlaufsparameter</h6>
            <el-input v-model="form.progress.evaluation.result"></el-input>
          </el-col>
        </el-row>

      </el-tab-pane>

      <el-tab-pane label="Diagnosen & Befunde">

        <el-row :class="('mt-4' + (markErrors.diagnosis ? ' border-danger border alert-danger' : ''))" v-if="currentPatient.diagnosis">
          <h6>Behandelte Diagnosen</h6>
          <el-col>
            <el-checkbox-group v-model="currentTherapy.local_diagnosis">
              <el-checkbox v-for="patient_diagnosis in currentPatient.diagnosis" :key="patient_diagnosis.id" :label="patient_diagnosis.id">
                <span v-if="patient_diagnosis.meta.diagnosis_metastasis">{{patient_diagnosis.meta.diagnosis_metastasis.title}}, {{patient_diagnosis.meta.diagnosis.title}}</span>
                <span v-else-if="patient_diagnosis.meta.diagnosis">{{patient_diagnosis.meta.diagnosis.code}}, {{patient_diagnosis.meta.diagnosis.title}}</span>
              </el-checkbox>
            </el-checkbox-group>
          </el-col>
        </el-row>

        <el-row class="mt-4" v-if="currentPatient.findings">
          <h6>Verknüpfte Befunde</h6>
          <el-col>
            <el-checkbox-group v-model="currentTherapy.local_findings">
              <el-checkbox v-for="patient_finding in currentPatient.findings" :key="patient_finding.id" :label="patient_finding.id">
                {{$filters.germanDate(patient_finding.date)}},
                <span v-if="patient_finding.meta.diagnosticmethod">{{patient_finding.meta.diagnosticmethod.title}}</span>
                <span v-if="patient_finding.code">{{patient_finding.code}}</span>
              </el-checkbox>
            </el-checkbox-group>
          </el-col>
        </el-row>

      </el-tab-pane>
      <el-tab-pane label="OP Modul" :disabled="!isOPTherapy">
        <el-row class="mt-4">
          <el-col :span="7">
            <h6>OP Intention</h6>
            <el-select v-model="currentTherapy.op_intention" class="w-100">
              <el-option label="Kurativ" value="K"></el-option>
              <el-option label="Palliativ" value="P"></el-option>
              <el-option label="Diagnostisch" value="D"></el-option>
              <el-option label="Revision/Komplikation" value="R"></el-option>
              <el-option label="Sonstiges" value="S"></el-option>
              <el-option label="Fehlende Angabe" value="X"></el-option>
            </el-select>
          </el-col>
        </el-row>
        <el-row class="mt-4">
          <el-col :span="24">
            <h6>OP OPS Codes</h6>
            <div v-for="(item, index) in currentTherapy.op_ops" :key="index" class="d-inline-block mr-3" style="width:150px;">
              <h6 style="font-weight: lighter">Code {{(index+1)}}</h6>
              <el-input v-model="currentTherapy.op_ops[index].code" :clearable="true"></el-input>
            </div>
            <el-button type="primary" @click="onBtnNewOPS" size="mini" icon="el-icon-circle-plus"></el-button>
          </el-col>
        </el-row>
        <el-row class="mt-4">
          <el-col :span="24">
            <h6>OP Operateure</h6>
            <div v-for="(item, index) in currentTherapy.op_operators" :key="index" class="mt-3">
              <h6 style="font-weight: lighter">Operateur {{(index+1)}}</h6>
              <el-row>
                <el-col :span="6">
                  Vorname
                  <el-input v-model="currentTherapy.op_operators[index].firstname" :clearable="true"></el-input>
                </el-col>
                <el-col :span="6" :offset="1">
                  Nachname
                  <el-input v-model="currentTherapy.op_operators[index].name" :clearable="true"></el-input>
                </el-col>
                <el-col :span="3" :offset="1">
                  <div>Hauptoperateur?</div>
                  <el-switch v-model="currentTherapy.op_operators[index].is_main"></el-switch>
                </el-col>
                <el-col :span="3" v-if="(index+1) == currentTherapy.op_operators.length">
                  <el-button type="primary" @click="onBtnNewOperator" size="mini" icon="el-icon-circle-plus" class="mt-4"></el-button>
                </el-col>
              </el-row>
            </div>
          </el-col>
        </el-row>
        <el-row class="mt-4">
          <el-col :span="24">
            <h6>OP Komplikationen</h6>
            <div v-for="(item, index) in currentTherapy.op_complications" :key="index" class="mt-3">
              <h6 style="font-weight: lighter">Komplikation {{(index+1)}}</h6>
              <el-row>
                <el-col :span="6">
                  Kürzel
                  <el-input v-model="currentTherapy.op_complications[index].code" :clearable="true"></el-input>
                </el-col>
                <el-col :span="6" :offset="1">
                  ICD-Code
                  <el-input v-model="currentTherapy.op_complications[index].icd_code" :clearable="true"></el-input>
                </el-col>
                <el-col :span="3" v-if="(index+1) == currentTherapy.op_complications.length" class="align-text-bottom">
                  <el-button type="primary" @click="onBtnNewComplication" size="mini" icon="el-icon-circle-plus" class="ml-3 mt-4"></el-button>
                </el-col>
              </el-row>
            </div>
          </el-col>
        </el-row>


      </el-tab-pane>
      <el-tab-pane label="Strahlentherapie Modul" :disabled="!isRadioTherapy">
        <el-row class="mt-4">
          <el-col :span="7">
            <h6>Strahlentherapie Intention</h6>
            <el-select v-model="currentTherapy.radio_intention" class="w-100">
              <el-option label="Kurativ" value="K"></el-option>
              <el-option label="Palliativ" value="P"></el-option>
              <el-option label="Lokal kurativ bei Oligometastasierung" value="O"></el-option>
              <el-option label="Sonstiges" value="S"></el-option>
              <el-option label="Keine Angabe" value="X"></el-option>
            </el-select>
          </el-col>
          <el-col :span="7" :offset="1">
            <h6>Stellung zu operativer Therapie</h6>
            <el-select v-model="currentTherapy.radio_op_position" class="w-100">
              <el-option label="Ohne Bezug zu einer operativen Therapie" value="O"></el-option>
              <el-option label="Adjuvant" value="A"></el-option>
              <el-option label="Neoadjuvant" value="N"></el-option>
              <el-option label="Intraoperativ" value="I"></el-option>
              <el-option label="Additiv" value="Z"></el-option>
              <el-option label="Sonstiges" value="S"></el-option>
            </el-select>
            <small>adjuvant nach R0, additiv nach R1/R2 und RX Resektion</small>
          </el-col>
          <el-col :span="7" :offset="1">
            <h6>Ende Grund</h6>
            <el-select v-model="currentTherapy.radio_end_reason" class="w-100">
              <el-option label="-- Keine Auswahl --" :value="null"></el-option>
              <el-option label="Reguläres Ende" value="E"></el-option>
              <el-option label="Zieldosis erreicht mit Unterbrechung > 3 Kalendertage" value="F"></el-option>
              <el-option label="Abbruch wegen Nebenwirkungen" value="A"></el-option>
              <el-option label="Abbruch wegen Progress" value="P"></el-option>
              <el-option label="Abbruch aus sonstigen Gründen" value="S"></el-option>
              <el-option label="Patient verweigert weitere Therapie" value="V"></el-option>
              <el-option label="Patient verstorben" value="T"></el-option>
              <el-option label="Unbekannt" value="U"></el-option>
            </el-select>
          </el-col>
        </el-row>
        <el-row class="mt-2">
          <el-col :span="24">
            <h6>Strahlentherapie <el-button type="primary" @click="onBtnNewRadio" size="mini" icon="el-icon-circle-plus" class="ml-3"></el-button></h6>
            <el-tabs>

              <el-tab-pane v-for="(item, index) in currentTherapy.radios" :key="index" class="mt-3" :label="('Strahlentherapie ' + (index+1))">
                <el-row>
                  <el-col :span="12">
                    Applikationsart
                    <el-radio-group v-model="item.application_type">
                      <el-radio label="Perkutan" >Perkutan</el-radio>
                      <el-radio label="Kontakt" >Kontakt</el-radio>
                      <el-radio label="Metabolisch" >Metabolisch</el-radio>
                      <el-radio label="Sonstige" >Sonstige</el-radio>
                    </el-radio-group>
                  </el-col>
                </el-row>

                <div  v-if="item.application_type == 'Perkutan'">

                  <el-row>
                    <el-col :span="6">
                      <el-checkbox v-model="item.perkutan_radio" class="d-block m-0 h-auto">Mit Chemotherapie/Sensitizer</el-checkbox>
                      <el-checkbox v-model="item.perkutan_stereo" class="d-block m-0 h-auto">Stereotaktisch</el-checkbox>
                      <el-checkbox v-model="item.perkutan_breath" class="d-block m-0 h-auto">Atemgetriggert</el-checkbox>
                    </el-col>
                  </el-row>

                </div>

                <div  v-if="item.application_type == 'Kontakt'">

                  <el-row>
                    <el-col :span="6">
                      Interstitiell oder Endokavitär
                      <el-select v-model="item.contact_type" class="w-100">
                        <el-option label="-- Keine Auswahl --" :value="null"></el-option>
                        <el-option label="Interstitiell" value="I"></el-option>
                        <el-option label="Endokavitär" value="K"></el-option>
                      </el-select>
                    </el-col>
                    <el-col :span="6" :offset="1">
                      Rate
                      <el-select v-model="item.contact_rate" class="w-100">
                        <el-option label="-- Keine Auswahl --" :value="null"></el-option>
                        <el-option label="high dose rate therapy" value="HDR"></el-option>
                        <el-option label="low dose rate therapy" value="LDR"></el-option>
                        <el-option label="pulsed dose rate therapy" value="PDR"></el-option>
                      </el-select>
                    </el-col>
                  </el-row>

                </div>

                <div  v-if="item.application_type == 'Metabolisch'">

                  <el-row>
                    <el-col :span="6">
                      Metabolisch Typ
                      <el-select v-model="item.metabolic_type" class="w-100">
                        <el-option label="-- Keine Auswahl --" :value="null"></el-option>
                        <el-option label="Selektive interne Radio-Therapie" value="SIRT"></el-option>
                        <el-option label="Peptid-Radio-Rezeptor-Therapie" value="PRRT"></el-option>
                        <el-option label="PSMA-Therapie" value="PSMA"></el-option>
                        <el-option label="Radiojod-Therapie" value="RJT"></el-option>
                        <el-option label="Radioimmun-Therapie" value="RIT"></el-option>
                      </el-select>
                    </el-col>

                  </el-row>

                </div>

                <el-row class="mt-2">
                  <el-col :span="6">
                    Zielgebiet (CodeVersion2021)
                    <el-input v-model="item.target"></el-input>
                  </el-col>
                  <el-col :span="6" :offset="1">
                    Seite Zielgebiet
                    <el-select v-model="item.target_site" class="w-100">
                      <el-option label="-- Keine Auswahl --" :value="null"></el-option>
                      <el-option label="Links" value="L"></el-option>
                      <el-option label="Rechts" value="R"></el-option>
                      <el-option label="Beidseits" value="B"></el-option>
                      <el-option label="Mittig" value="M"></el-option>
                      <el-option label="Unbekannt" value="U"></el-option>
                      <el-option label="Trifft nicht zu" value="T"></el-option>
                    </el-select>
                  </el-col>
                </el-row>
                <el-row class="mt-2" v-if="item.application_type != 'Sonstige'">
                  <el-col :span="13">
                    Strahlenart
                    <el-select v-model="item.radio_type" class="w-100">
                      <el-option label="-- Keine Auswahl --" :value="null"></el-option>
                      <el-option label="Photonen (ultraharte Röntgenstrahlen, inkl. Gamma-Strahler)" value="UH"></el-option>
                      <el-option label="Elektronen" value="EL"></el-option>
                      <el-option label="Neutronen" value="NE"></el-option>
                      <el-option label="Protonen (leichte Wasserstoffionen/H1/Leichtionen)" value="PN"></el-option>
                      <el-option label="Schwerionen (schwere Kohlenstoff-Ionen/C12/Sauerstoffionen/Heliumionen)" value="SI"></el-option>
                      <el-option label="Co-60" value="Co-60"></el-option>
                      <el-option label="Sonstige" value="SO"></el-option>
                    </el-select>
                  </el-col>
                </el-row>
                <el-row class="mt-2">
                  <el-col :span="6">
                    Einzeldosis (Gy)
                    <el-input v-model="item.dose_single"></el-input>
                  </el-col>
                  <el-col :span="6" :offset="1">
                    Gesamtdosis (Gy)
                    <el-input v-model="item.dose_total"></el-input>
                  </el-col>
                  <el-col :span="6" :offset="1" v-if="item.application_type == 'Perkutan' || item.application_type == 'Kontakt'">
                    Boost
                    <el-select v-model="item.boost" class="w-100">
                      <el-option label="-- Keine Auswahl --" :value="null"></el-option>
                      <el-option label="Ja, mit Boost o. n. A." value="J"></el-option>
                      <el-option label="Simultan integrierter Boost" value="SIB"></el-option>
                      <el-option label="Sequentieller Boost" value="SEQ"></el-option>
                      <el-option label="Konkominanter Boost" value="KON"></el-option>
                      <el-option label="Nein, ohne Boost" value="N"></el-option>
                    </el-select>
                  </el-col>
                </el-row>
                <el-row class="mt-2">
                  <el-col :span="20">
                    Kommentare
                    <el-input
                      type="textarea"
                      :rows="2"
                      placeholder="Kommentare"
                      v-model="item.comment"></el-input>
                  </el-col>
                </el-row>

              </el-tab-pane>
            </el-tabs>

          </el-col>
        </el-row>
      </el-tab-pane>
    </el-tabs>
    <template #footer>
      <el-row>

        <el-col :span="7" class="text-left">
          &nbsp;
          <el-popconfirm
              v-if="currentTherapy.id"
              title="Sie möchten diese Therapiezuweisung löschen?"
              confirmButtonText='Ja, löschen'
              confirmButtonType="danger"
              cancelButtonText='Nein'
              iconColor="red"
              @confirm="deleteTherapy">
            <template #reference>
              <el-button type="danger">Löschen</el-button>
            </template>
          </el-popconfirm>


            <el-dropdown icon="el-icon-download" class="ml-2">
                <el-button type="secondary" icon="el-icon-download" :disabled="!isSystemTherapy && !isOPTherapy && !isRadioTherapy">
                    Krebsregister
                </el-button>
                <template #dropdown>
                    <el-dropdown-menu>
                      <el-dropdown-item :disabled="!isSystemTherapy" @click="onGenerateCancerRegistryXML('system')">Systemtherapie</el-dropdown-item>
                      <el-dropdown-item :disabled="!isOPTherapy" @click="onGenerateCancerRegistryXML('op')">OP</el-dropdown-item>
                      <el-dropdown-item :disabled="!isRadioTherapy" @click="onGenerateCancerRegistryXML('radio')">Strahlentherapie</el-dropdown-item>
                      <el-dropdown-item @click="onGenerateCancerRegistryXML('all')">Alle</el-dropdown-item>
                    </el-dropdown-menu>
                </template>
            </el-dropdown>

        </el-col>
        <el-col :span="17" class="dialog-footer text-right">
          <el-button type="primary" @click="gotoProgress">Speichern + Verlaufsbeobachtung anlegen</el-button>
          <el-button @click="closeDialog">Abbrechen</el-button>
            <el-button type="primary" @click="saveTherapy()">{{dialogSaveButtonTitle}}</el-button>
        </el-col>

      </el-row>

    </template>
  </el-dialog>
  </div>
</template>

<script>
import {mapState, mapGetters, mapActions} from 'vuex'
import moment from "moment";
import _ from "lodash";
import surveyPatientFormModal from "../survey/SurveyPatientFormModal";
import DatePickerPrecision from "../parts/DatePickerPrecision.vue";

export default {
  name: "PatientTherapyForm",
  props: ['modelValue', 'show', 'therapy'],
  components: {
    surveyPatientFormModal, DatePickerPrecision
  },
  data() {
    return {
      markErrors: {
        diagnosis: false
      },
      tabIndex: "0",
      showForm: false,
      showSurveyPatientForm: false,
      form: {
        addProgress: false,
        progress: {
          evaluation: {}
        }
      },
      currentTherapy: {},
      allTreatmentSituations: []
    }
  },
  mounted: async function () {

    this.showForm = this.modelValue
    this.currentTherapy = JSON.parse(JSON.stringify(this.therapy))
    this.currentTherapy.local_diagnosis = this.currentTherapy.diagnosis ? this.currentTherapy.diagnosis.map((d) => d.id) : []
    this.currentTherapy.local_findings = this.currentTherapy.findings ? this.currentTherapy.findings.map((f) => f.id) : []
    this.initForm()

    await this.getOncologyDiagnosis()
    await this.getTreatmentsituations()
    await this.getTherapyUnits()

    await this.getTherapies({ diagnosis: this.currentTherapy.diagnosis_id, protocol: this.currentTherapy.protocol_id, therapiesForForm: true })

    this.allTreatmentSituations = {...this.treatmentsituations}

  },
  computed: {
    ...mapGetters('patients', ['currentPatient', 'fullInfo']),
    ...mapGetters('config', ['modules_enabled']),
    ...mapState('patient_therapy_completed_states', ['patient_therapy_completed_states']),
    ...mapState('therapies', ['therapiesForForm']),
    ...mapState('diagnosis', ['oncologyDiagnosis']),
    ...mapState('treatmentsituations', ['treatmentsituations']),
    ...mapState('therapyunits', ['therapyunits']),
    dialogTitle() {
      return (this.currentTherapy && this.currentTherapy.id ? 'Therapie ändern' : 'Therapie zuweisen')
    },
    dialogSaveButtonTitle() {
      return (this.currentTherapy && this.currentTherapy.id ? 'Speichern' : 'Zuweisen')
    },
    dialogSuccessMessage() {
      if(this.currentTherapy && this.currentTherapy.id) {
        return ((this.currentTherapy.meta.protocol && this.currentTherapy.meta.protocol.title) || this.currentTherapy.meta.therapy.code) + ' bei ' + this.fullInfo + ' geändert'
      }
      else {
        return ((this.currentTherapy.meta.protocol && this.currentTherapy.meta.protocol.title) || this.currentTherapy.meta.therapy.code) + ' wurde ' + this.fullInfo + ' zugewiesen'
      }
    },
    dialogDeleteMessage() {
        return ((this.currentTherapy.meta.protocol && this.currentTherapy.meta.protocol.title) || this.currentTherapy.meta.therapy.code) + ' bei ' + this.fullInfo + ' gelöscht'
    },
    allTherapyProtocols() {
      if (this.therapiesForForm && this.currentTherapy) {

        if (this.currentTherapy.protocol_id === null || this.currentTherapy.therapy_id > 0) {

          for (const therapy of this.therapiesForForm) {
            if (therapy.id == this.currentTherapy.therapy_id) {
              return therapy.therapyProtocols
            }
          }

        }
        else {

          for (const therapy of this.therapiesForForm) {
            for (const therapyProtocol of therapy.therapyProtocols) {
              if (therapyProtocol.protocol_id == this.currentTherapy.protocol_id) {
                let returnTherapy = []
                returnTherapy.push(therapyProtocol)

                return returnTherapy
              }
            }
          }

        }
      }
      return []
    },
    therapyHasSurveys() {
      if(!this.currentTherapy.protocol_id || !this.allTherapyProtocols) return false
      return _.find(this.allTherapyProtocols, (p) => (p.protocol_id == this.currentTherapy.protocol_id && p.meta && p.meta.surveys && p.meta.surveys.length > 0))
    },
    surveysOfTherapyProtocol() {
      let therapySurveys = []
      for(const p of this.allTherapyProtocols) {
        if(p.meta && p.meta.surveys && p.meta.surveys.length > 0) {
          for(const tp of p.meta.surveys) {
            therapySurveys.push(tp.survey)
          }
        }
      }
      return therapySurveys
    },
    isRadioTherapy() {
      return (this.currentTherapy && (this.currentTherapy.treatmentsituation_id == 11 || this.currentTherapy.treatmentsituation_id == 23))
    },
    isOPTherapy() {
      return (this.currentTherapy && this.currentTherapy.meta && this.currentTherapy.meta.therapy && this.currentTherapy.meta.therapy.therapy_group_id == 3)
    },
    isSystemTherapy() {
      return (this.currentTherapy && ([2, 3, 4, 5, 6, 7, 14, 15, 19, 20, 21].indexOf(this.currentTherapy.treatmentsituation_id) !== -1))
    },
    allDiagnosis() {
      if(this.currentPatient && this.currentPatient.diagnosis && this.currentPatient.diagnosis.length > 0) {
        const filteredDiagnosis = []
        const addedIds = []
        for(const diagnosis of this.currentPatient.diagnosis) {
          if(diagnosis.is_primary && addedIds.indexOf(diagnosis.diagnosis_id) === -1) {
            filteredDiagnosis.push(diagnosis.meta.diagnosis)
            addedIds.push(diagnosis.diagnosis_id)
          }
        }
        return filteredDiagnosis
      }

      if(this.oncologyDiagnosis) return {...this.oncologyDiagnosis}

      return []
    }
  },
  watch: {
    modelValue: function (val) {
      this.showForm = val
    },
    therapy: async function (val) {
      this.currentTherapy = JSON.parse(JSON.stringify(val))
      this.currentTherapy.local_diagnosis = this.currentTherapy.diagnosis ? this.currentTherapy.diagnosis.map((d) => d.id) : []
      this.currentTherapy.local_findings = this.currentTherapy.findings ? this.currentTherapy.findings.map((f) => f.id) : []
      this.initForm()

      await this.getTherapies({ diagnosis: this.currentTherapy.diagnosis_id, protocol: this.currentTherapy.protocol_id, therapiesForForm: true })
    },
    'currentTherapy.date_end': function (newVal, oldVal) {
      if(oldVal !== undefined && newVal && newVal !== '') {
        this.currentTherapy.completed = 1
      }
    },
    'currentTherapy.therapy_id': function (newVal) {
      if(newVal !== undefined && newVal && newVal !== '') {
        const exist = _.find(this.therapiesForForm, (t) => (t.id == newVal))
        if(exist) this.currentTherapy.meta.therapy = exist
      }
    },
    'markErrors.diagnosis': function(newVal) {
      if(newVal === true) {
        window.setTimeout(() => {
          this.markErrors.diagnosis = false
        }, 2000)
      }
    }
  },
  methods: {
    ...mapActions('diagnosis', ['getOncologyDiagnosis']),
    ...mapActions('treatmentsituations', ['getTreatmentsituations']),
    ...mapActions('therapies', ['getTherapies', 'saveTherapyToPatient', 'deleteTherapyOfPatient']),
    ...mapActions('therapyunits', ['getTherapyUnits']),
    ...mapActions('cancerregistry', ['generateTherapyOPXml', 'generateSystemTherapyXml', 'generateRadioTherapyXml']),
    closeDialog() {
      this.$emit('update:modelValue', false)
    },
    async saveTherapy() {

      if(this.currentTherapy.therapy_id === null || this.currentTherapy.diagnosis_id === null || !this.currentTherapy.diagnosis_id) {
        this.$notify({
          message: 'Bitte Diagnose und Therapieprinzip wählen.',
          type: 'error',
          position: 'bottom-left'
        });
        return
      }

      if(!this.currentTherapy.local_diagnosis || (this.currentTherapy.local_diagnosis && this.currentTherapy.local_diagnosis.length === 0)) {

        this.$notify({
          message: 'Bitte eine Diagnose mit dieser Therapie verknüpfen.',
          type: 'error',
          position: 'bottom-left'
        });
        this.tabIndex = "1"
        this.markErrors.diagnosis = true
        return

      }

      this.currentTherapy.date_start = moment(this.currentTherapy.date_start).format("YYYY-MM-DD")
      this.currentTherapy.date_end = this.currentTherapy.date_end ? moment(this.currentTherapy.date_end).format("YYYY-MM-DD") : null

      for(const index in this.currentTherapy.op_ops) {
        if(this.currentTherapy.op_ops[index].code === null || this.currentTherapy.op_ops[index].code === '') {
          this.currentTherapy.op_ops.splice(index, 1);
        }
      }
      for(const index in this.currentTherapy.op_operators) {
        if((this.currentTherapy.op_operators[index].name === '' && this.currentTherapy.op_operators[index].firstname === '') || (this.currentTherapy.op_operators[index].name === null && this.currentTherapy.op_operators[index].firstname === null)) {
          this.currentTherapy.op_operators.splice(index, 1);
        }
      }
      for(const index in this.currentTherapy.op_complications) {
        if((this.currentTherapy.op_complications[index].code === '' && this.currentTherapy.op_complications[index].icd_code === '') || (this.currentTherapy.op_complications[index].code === null && this.currentTherapy.op_complications[index].icd_code === null)) {
          this.currentTherapy.op_complications.splice(index, 1);
        }
      }

      await this.saveTherapyToPatient({
        therapy: this.currentTherapy,
        progress_form: this.form
      })

      /*if(this.modules_enabled.cancerregistry) {

       const startTherapy = !this.currentTherapy.id
       const endTherapy = (this.currentTherapy.id && this.currentTherapy.date_end !== null)

      if(startTherapy || endTherapy) {

         this.$messageBox.confirm('Soll diese' + (startTherapy ? 'r Therapiebeginn' : 's Therapieende') + ' dem Krebsregister gemeldet werden?', 'Krebsregister', { confirmButtonText: 'Ja', cancelButtonText: 'Nein', type: 'warning' }).then(async () => {
             this.onGenerateCancerRegistryXML()
           })
         }


     }*/

      this.$emit('after-save')

      this.closeDialog()
      this.$notify({
        message: this.dialogSuccessMessage,
        type: 'success',
        position: 'bottom-left'
      });

    },
    async deleteTherapy() {
      await this.deleteTherapyOfPatient(this.currentTherapy)

      this.$emit('after-save')

      this.closeDialog()
      this.$notify({
        message: this.dialogDeleteMessage,
        type: 'success',
        position: 'bottom-left'
      });

    },
    async gotoProgress() {
      await this.saveTherapy()
      this.showForm = false
      this.$router.push({ name: 'progress', params: { id: this.currentPatient.id }})
    },
    onBtnNewOPS() {
        this.currentTherapy.op_ops.push({id: 0, code: null})
    },
    onBtnNewOperator() {
        this.currentTherapy.op_operators.push({id: 0, name: null, firstname: null, is_main: false})
    },
    onBtnNewComplication() {
        this.currentTherapy.op_complications.push({ id: 0, code: null, icd_code: null })
    },
    onBtnNewRadio() {
      this.currentTherapy.radios.push({
        id: 0,
        application_type: 'Perkutan',
        perkutan_radio: false,
        perkutan_breath: false,
        perkutan_stereo: false,
        contact_type: null,
        contact_rate: null,
        metabolic_type: null,
        target: '',
        target_site: null,
        radio_type: null,
        dose_single: '',
        dose_total: '',
        boost: null,
        comment: ''
      })
    },
    initForm() {
      if (!this.currentTherapy.op_ops) this.currentTherapy.op_ops = []
      if (!this.currentTherapy.op_complications) this.currentTherapy.op_complications = []
      if (!this.currentTherapy.op_operators) this.currentTherapy.op_operators = []
      if (!this.currentTherapy.radios) this.currentTherapy.radios = []

      if (this.currentTherapy.op_ops.length === 0) this.onBtnNewOPS()
      if (this.currentTherapy.op_complications.length === 0) this.onBtnNewComplication()
      if (this.currentTherapy.op_operators.length === 0) this.onBtnNewOperator()

      if(!this.currentTherapy.diagnosis_id && this.allDiagnosis.length === 1) this.currentTherapy.diagnosis_id = this.allDiagnosis[0].id

    },
    async onGenerateCancerRegistryXML(type) {
      let xmlResult, label
      if(type == 'op') {
        xmlResult = await this.generateTherapyOPXml(this.currentTherapy)
        label = "OP Meldung an das Krebsregister wurde"
      }
      if(type == 'system') {
        xmlResult = await this.generateSystemTherapyXml(this.currentTherapy)
        label = "Systemtherapie Meldung an das Krebsregister wurde"
      }
      if(type == 'radio') {
        xmlResult = await this.generateRadioTherapyXml(this.currentTherapy)
        label = "Strahlentherapie Meldung an das Krebsregister wurde"
      }
      if(type == 'all') {
        xmlResult = await this.generateTherapyOPXml(this.currentTherapy)
        label = "Alle therapiebezogenen Meldungen an das Krebsregister wurden"
      }
      this.$notify({
        message: label + ( xmlResult ? ' ' : ' nicht ' ) + 'generiert',
        type: (xmlResult ? 'success' : 'error'),
        position: 'bottom-left'
      });
    }
  }
}
</script>
<style scoped>
</style>
