class DacyServer {

    static instance;

    constructor() {
    }

    static getInstance() {
        if (DacyServer.instance) {
            return DacyServer.instance;
        }
        DacyServer.instance = new DacyServer();
        return DacyServer.instance;
    }

    setService(service) {
        DacyServer.instance.service = service
    }

    getURL() {
        return (DacyServer.instance.service.protocol ? DacyServer.instance.service.protocol : 'http') + '://' + (DacyServer.instance.service.host || DacyServer.instance.service.ip) + ':' + DacyServer.instance.service.port
    }
}

export default DacyServer
