import axios from 'axios'

// initial state
const state = () => ({
  ICDO3H: [],
  ICDO3T: []
})

// getters
const getters = {
  ICDO3H: ({ ICDO3H }) => ICDO3H,
  ICDO3T: ({ ICDO3T }) => ICDO3T,
}

// actions
const actions = {
    async getICDO3H({ commit }, diagnosis_id = null) {
      let icd = []
      if(diagnosis_id) {
        icd = (await axios.get('/meta/diagnosis/icdo3h?diagnosis_id=' + diagnosis_id)).data
        if(icd.length > 0 && icd[0].ICDO3H) {
          icd = icd.map((item) => ({description: item.ICDO3H.description ,...item}))
          console.log(icd)
        }
      }
      else {
        icd = (await axios.get('/meta/icdo3h')).data
      }
      commit('setICDO3H', icd)
    },
    async getICDO3T({ commit }, diagnosis_id = null) {
      let icd = []
      if(diagnosis_id) {
        icd = (await axios.get('/meta/diagnosis/icdo3t?diagnosis_id=' + diagnosis_id)).data
        if(icd.length > 0 && icd[0].ICDO3T) {
          icd = icd.map((item) => ({description: item.ICDO3T.description ,...item}))
          console.log(icd)
        }
      }
      else {
        icd = (await axios.get('/meta/icdo3t')).data
      }
      commit('setICDO3T', icd)
    }
}

// mutations
const mutations = {
  setICDO3H(state, ICDO3H) {
    state.ICDO3H = ICDO3H
  },
  setICDO3T(state, ICDO3T) {
    state.ICDO3T = ICDO3T
  }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
