import axios from 'axios'

// initial state
const state = () => ({
    treatmentsituations: [],
    publicTreatmentsituations: []
})

// getters
const getters = {
  treatmentsituations: ({ treatmentsituations }) => treatmentsituations,
  publicTreatmentsituations: ({ publicTreatmentsituations }) => publicTreatmentsituations,
}

// actions
const actions = {
    async getTreatmentsituations({ commit }) {
        const treatmentsituations = (await axios.get('/meta/treatmentsituations/')).data
        commit('setTreatmentsituations', treatmentsituations)
    },
    async getPublicTreatmentsituations({ commit }) {
      const publicTreatmentsituations = (await axios.get('/public/meta/treatmentsituations/')).data
      commit('setPublicTreatmentsituations', publicTreatmentsituations)
    }
}

// mutations
const mutations = {
    setTreatmentsituations(state, treatmentsituations) {
        state.treatmentsituations = treatmentsituations
    },
    setPublicTreatmentsituations(state, publicTreatmentsituations) {
      state.publicTreatmentsituations = publicTreatmentsituations
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
